<template>
    <div id="dis">
        支付成功
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'pay_status',
    data(){
      return {
  
      }
    },
    created(){
      window.location = `xhsdiscover://`
    },
    methods:{
  
    }
  }
  </script>
  
  <style>
  
  </style>
  